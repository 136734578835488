<template>
  <div>
    <top-header v-bind:title="$t('productPerformance', { siteId: siteId })">
      <span class="white--text"
            slot="right">
        <strong>{{ $t('stock') }}</strong>: {{ date | formatDate('L') }}<br>
        <strong>{{ $t('salesDefaultUnit') }}</strong>: {{ lastDate | formatDate('MM/YY') }}– {{ date | formatDate('MM/YY') }}
      </span>
      <v-tabs background-color="transparent"
              dark
              slot="tabs"
              v-model="tab">
        <v-tab href="#range">{{ $t('range') }}</v-tab>
        <v-tab href="#top">{{ $t('top') }}</v-tab>
        <v-tab href="#flop">{{ $t('flop') }}</v-tab>
      </v-tabs>
    </top-header>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <v-tabs-items class="transparent"
                  v-model="tab">
      <v-tab-item value="range">
        <v-container>
          <product-performance-table-card v-bind:groupFilter.sync="group" v-bind:products="productPerformance" v-on:change-group="changeGroup" />
        </v-container>
      </v-tab-item>
      <v-tab-item value="top">
        <v-container>
          <product-rank-table-card v-bind:groupFilter.sync="group" v-bind:products="top"  v-on:change-group="changeGroup" />
        </v-container>
      </v-tab-item>
      <v-tab-item value="flop">
        <v-container>
          <product-rank-table-card v-bind:groupFilter.sync="group" v-bind:products="flop" v-on:change-group="changeGroup"  />
        </v-container>
      </v-tab-item>

    </v-tabs-items>
  </div>
</template>

<script>
  import moment from 'moment'

  import { mapActions, mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader.vue'
  import ProductPerformanceTableCard from '@/components/product-performance/ProductPerformanceTableCard.vue'
  import ProductRankTableCard from '@/components/product-performance/ProductRankTableCard.vue'

  export default {
    components: {
      ProductPerformanceTableCard,
      ProductRankTableCard,
      TopHeader
    },
    computed: {
      lastDate() {
        if (this.date == null) {
          return null
        }

        return moment(this.date).subtract(11, 'month')
      },
      date() {
        if (this.productPerformance == null || this.productPerformance.length === 0) {
          return null
        }

        return this.productPerformance[0]?.date
      },
      top() {
        if (this.productPerformance == null) {
          return []
        }

        return [...this.productPerformance].sort((a, b) => b.salesDefaultUnit - a.salesDefaultUnit)
      },
      flop() {
        if (this.productPerformance == null) {
          return []
        }

        return [...this.productPerformance].sort((a, b) => a.salesDefaultUnit - b.salesDefaultUnit)
      },
      ...mapGetters({
        currentSiteId: 'common/currentSiteId',
        productPerformance: 'evaluation/productPerformance',
        kinds: 'productPerformance/kinds',
        groups: 'productPerformance/groups'
      })
    },
    async created() {
      this.siteId = this.currentSiteId

      await this.loadGroups()
      await this.loadKinds()

      await this.reload()
    },
    data: () => ({
      siteId: null,
      tab: null,
      loading :false,
      group: 'AFG Mehrweg'
    }),
    methods: {
      async changeGroup(group) {
        this.group = group

        await this.reload()
      },
      async reload() {
        this.loading = true

        await this.loadProductPerformance({ id: this.siteId, group: this.group })

        this.loading = false
      },
      ...mapActions({
        loadProductPerformance: 'evaluation/loadProductPerformance',
        loadGroups: 'productPerformance/loadGroups',
        loadKinds: 'productPerformance/loadKinds'
      })
    },
    name: 'SiteProductPerformance'
  }
</script>

<i18n>
  {
    "de": {
      "flop": "Penner",
      "productPerformance": "Artikelperformance {siteId}",
      "range": "Reichweite",
      "top": "Renner",
      "stock": "Bestand",
      "salesDefaultUnit": "Verkauf GE"
    },
    "en": {
      "flop": "Flop",
      "productPerformance": "Proudt performance {siteId}",
      "range" : "Range",
      "top": "Top",
      "stock": "Stock",
      "salesDefaultUnit": "Sales default unit"
    }
  }
</i18n>