<template>
  <v-list class="py-0"
          color="secondary"
          dark>
    <v-list-item v-bind:to="{ name: 'free-product-accounting-receipts' }"
                 v-if="hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalSite')">
      <v-list-item-icon>
        <v-icon>mdi-gift</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('freeProductAccounting') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'evaluation-site' }"
                 v-if="hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalSiteEvaluation')">
      <v-list-item-icon>
        <v-icon>mdi-star</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('siteEvaluation') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'pre-orders-site' }"
                 v-if="hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalSiteEvaluation')">
      <v-list-item-icon>
        <v-icon>mdi-cart</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('sitePreOrders') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'product-performance-site' }"
                 v-if="hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalSiteEvaluation')">
      <v-list-item-icon>
        <v-icon>mdi-podium-gold</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('productPerformance') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'price-tag' }"
                 v-if="hasRole('SagasserPortalPriceTag')">
      <v-list-item-icon>
        <v-icon>mdi-tag</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('priceTagManagement') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'sale-management-campaigns' }"
                 v-if="hasRole('SagasserPortalAdministrator')">
      <v-list-item-icon>
        <v-icon>mdi-sale</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('saleManagement') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'sale-management-site-campaigns' }"
                 v-if="hasRole('SagasserPortalSite')">
      <v-list-item-icon>
        <v-icon>mdi-sale</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('myCampaigns') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'learning' }"
                 v-if="hasRole('SagasserPortalAdministrator')">
      <v-list-item-icon>
        <v-icon>mdi-school</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('learning') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'booking-events' }"
                 v-if="hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalBookingUser')">
      <v-list-item-icon>
        <v-icon>mdi-calendar</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('events') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'meter-management' }"
                 v-if="hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalMeterManagement')">
      <v-list-item-icon>
        <v-icon>mdi-gauge</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('meterManagement') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'departments' }"
                 v-if="hasRole('SagasserPortalAdministrator')">
      <v-list-item-icon>
        <v-icon>mdi-domain</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('departments') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'inventory-management-inventories' }"
                 v-if="hasRole('SagasserPortalAdministrator')">
      <v-list-item-icon>
        <v-icon>mdi-warehouse</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('inventories') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-bind:to="{ name: 'administration' }"
                 v-if="hasRole('SagasserPortalAdministrator')">
      <v-list-item-icon>
        <v-icon>mdi-toolbox</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('administration') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        hasRole: 'auth/hasRole'
      })
    },
    name: 'AdministrationNavigation'
  }
</script>

<i18n>
  {
    "de": {
      "administration": "Administration",
      "departments": "Abteilungen",
      "events": "Seminare",
      "freeProductAccounting": "Gratiswarenabrechnung",
      "learning": "Unterweisung",
      "myCampaigns": "Meine Aktionen",
      "productPerformance": "Artikelperformance",
      "saleManagement": "Aktionsabfrage",
      "siteEvaluation": "Marktbewertung",
      "sitePreOrders": "Vorbestellungen",
      "meterManagement": "Zählerstände",
      "inventories": "Inventuren",
      "priceTagManagement": "Preisschilddruck"
    },
    "en": {
      "administration": "Administration",
      "departments": "Departments",
      "events": "Events",
      "freeProductAccounting": "Free product accounting",
      "learning": "Learning",
      "myCampaigns": "My campaigns",
      "productPerformance": "Product performance",
      "saleManagement": "Sale management",
      "siteEvaluation": "Site evaluation",
      "sitePreOrders": "Site pre orders",
      "meterManagement": "Meter management",
      "inventories": "Inventories",
      "priceTagManagement": "Price tag management"
    }
  }
</i18n>